import React from 'react';

import {
  Classes,
  Tabs,
} from '@blueprintjs/core'

import { useMediaQuery } from 'hooks'
import { Register, SignIn } from 'pages'

import './App.css'

function App() {
  const darkMode = useMediaQuery('(prefers-color-scheme: dark)')
  React.useEffect(() => {
    // Many BP elements expect a low-level class for dark mode. Portals (which
    // are for dialogs, etc) live in the DOM _next to_ #root, so we'll tag the
    // main body instead.
    const body = document.querySelector('body')
    body!.className = darkMode ? Classes.DARK : ''
  }, [darkMode])

  return (<div id="App">
    <h1>SnapAuth Demo</h1>
    <nav>
      <ul>
        
      </ul>
    </nav>
    <Tabs id="rgsi" defaultSelectedTabId="rg" renderActiveTabPanelOnly>
      <Tabs.Tab id="rg" title="Register" panel={<Register />} />
      <Tabs.Tab id="si" title="Sign In" panel={<SignIn />} />
    </Tabs>
  </div>)
}

export default App
