import React, { useState } from 'react'
import { RegisterResponse } from '@snapauth/sdk'

import {
  Button,
  FormGroup,
  InputGroup,
  Pre,
  Section,
  SectionCard,
} from '@blueprintjs/core'

import { usePretendBackend } from 'hooks'
import { toaster, snapAuth } from 'utilities'

const Register: React.FC = () => {
  const backend = usePretendBackend()
  const [username, setUsername] = React.useState('')
  const [name, setName] = React.useState('')

  const [regResult, setRegResult] = React.useState<RegisterResponse|null>()
  const [attachRequest, setAttachRequest] = useState<DictOf<any>|null>()
  const [attachResult, setAttachResult] = React.useState<{ data: any, status: number, statusText: string }|null>()

  const register = async (e: React.FormEvent) => {
    e.preventDefault()
    setRegResult(null)
    setAttachResult(null)
    const result = await snapAuth.startRegister({ name })
    setRegResult(result)
    if (result.ok) {
      const request = { token: result.data.token, user: { id: username }}
      setAttachRequest(request)
      const attach = await backend.post('/registration/attach', request)
      const attachJson = await attach.json()
      setAttachResult({ data: attachJson, status: attach.status, statusText: attach.statusText })
      toaster.show({ message: 'Registered! Try signing in', intent: 'success' })
    } else {
      toaster.show({ message: 'Registration failed.', intent: 'warning' })
    }
  }

  return (<>
    <form onSubmit={register}>
      <FormGroup helperText="User-defined identifer">
        <InputGroup
          autoComplete="off"
          leftIcon="user"
          onValueChange={setUsername}
          placeholder="Username"
          required
          value={username}
        />
      </FormGroup>
      <FormGroup helperText="Visible to user during authentication">
        <InputGroup
          autoComplete="name"
          leftIcon="id-number"
          onValueChange={setName}
          value={name}
          required
          placeholder="Your Name"
        />
      </FormGroup>
      <Button type="submit" icon="user" intent="primary" text="Sign Up" />
    </form>

    {regResult && <Section title="JS `startRegister` Response" icon="code" collapsible>
      <SectionCard>
        <output>
          <Pre>{JSON.stringify(regResult, null, 2)}</Pre>
        </output>
      </SectionCard>
    </Section>}

    {attachRequest && <Section title="Backend attach request" icon="lock" collapsible>
      <SectionCard>
        <output>
          <Pre>
            POST /registration/attach HTTP/1.1{"\n"}
            Content-type: application/json{"\n"}
            Authorization: Basic ******{"\n"}
            {"\n"}
            {JSON.stringify(attachRequest, null, 2)}
          </Pre>
        </output>
      </SectionCard>
    </Section>}

    {attachResult && <Section title="Backend attach response" icon="lock" collapsible>
      <SectionCard>
        <output>
          <Pre>
            HTTP {attachResult.status} {attachResult.statusText}{"\n"}
            {"\n"}
            {JSON.stringify(attachResult.data, null, 2)}
          </Pre>
        </output>
      </SectionCard>
    </Section>}
  </>)
}

export default Register
