// Pull values from the runtime template at public/index.html
// (there's an `envsubst` trick that runs at deploy, .env works locally)
// @ts-ignore
const INJECTED_ENV_DATA: DictOf<string> = window.ENV || {}
const BUILDTIME_ENV_DATA = process.env || {} as DictOf<string>

// Follow the REACT_APP_ prefix convention
const SANITIZED_INJECTED_ENV_DATA = Object.keys(INJECTED_ENV_DATA)
  .filter((key) => key === 'NODE_ENV' || key.startsWith('REACT_APP_'))
  .reduce((obj, key) => {
    const value = INJECTED_ENV_DATA[key]
    // Remove blanks or non-substituted values
    if (value !== '' && !value.startsWith('$')) {
      // @ts-ignore
      obj[key] = value
    }
    return obj
  }, {})

const combined: DictOf<string> = {
  ...BUILDTIME_ENV_DATA,
  ...SANITIZED_INJECTED_ENV_DATA,
}

/******************************************************************************
 * IMPORTANT                                                                  *
 *                                                                            *
 * You must add a matching entry to public/index.html for any of this to work *
 ******************************************************************************/

export const NODE_ENV = combined.NODE_ENV as 'production' | 'staging' | 'development'
export const SDK_HOST = combined.REACT_APP_SDK_HOST || undefined
export const SDK_PUBLIC_KEY = combined.REACT_APP_SDK_PUBLIC_KEY
export const SDK_SECRET_KEY_DONT_DO_THIS = combined.REACT_APP_SDK_SECRET_KEY
