import * as config from 'config'

export { default as useMediaQuery } from './useMediaQuery'



interface UserInfo {
  id: string
  handle?: string
}

export const usePretendBackend = () => {
  const secretKey = config.SDK_SECRET_KEY_DONT_DO_THIS
  const host = config.SDK_HOST

  const post = async (path: string, body: DictOf<any>) => await fetch(host + path, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      'Authorization': 'Basic ' + btoa(`:${secretKey}`),
    },
    body: JSON.stringify(body),
  })

  return {
    post,

    attachRegistration: async (token: string, user: UserInfo) => await post('/registration/attach', { token, user }),

    validateToken: async (token: string) => await post('/auth/verify', { token }),
  }
}
