import React, { useState } from 'react'
import { AuthResponse } from '@snapauth/sdk'

import {
  Button,
  FormGroup,
  InputGroup,
  Pre,
  Switch,
  Section,
  SectionCard,
} from '@blueprintjs/core'

import { usePretendBackend } from 'hooks'
import { toaster, snapAuth } from 'utilities'

const SignIn: React.FC = () => {
  const backend = usePretendBackend()
  const [username, setUsername] = React.useState('')
  const [autoFillOn, setAutofillOn] = React.useState(false)

  const [authResult, setAuthResult] = React.useState<AuthResponse|null>() // TODO: export result type
  const [verifyRequest, setVerifyRequest] = useState<DictOf<any>|null>()
  const [verifyResult, setVerifyResult] = React.useState<{ data: any, response: Response }|null>()

  const processResult = async (result: AuthResponse) => {
    setAuthResult(result)
    setVerifyResult(null)
    if (result.ok) {
      const request = { token: result.data.token }
      setVerifyRequest(request)

      const verification = await backend.post('/auth/verify', request)
      const verifyJson = await verification.json()
      setVerifyResult({ response: verification, data: verifyJson })
      toaster.show({ message: 'Signed in!', intent: 'success' })
    } else {
      toaster.show({ message: 'Sign in failed', intent: 'warning' })
    }
  }

  React.useEffect(() => {
    if (autoFillOn) {
      snapAuth.handleAutofill(processResult)
    }
  }, [autoFillOn])

  const startAuth = async (e: React.FormEvent) => {
    e.preventDefault()
    setAuthResult(null)
    setVerifyResult(null)
    const result = await snapAuth.startAuth({ id: username })
    processResult(result)
  }

  return (<>
    <form onSubmit={startAuth}>
      <FormGroup helperText="The username from register">
        <InputGroup
          autoComplete={autoFillOn ? 'username webauthn' : 'username'}
          leftIcon="user"
          onValueChange={setUsername}
          placeholder="Username"
          required
          value={username}
        />
      </FormGroup>
      <Button type="submit" icon="key" intent="primary" text="Sign In" />
      <FormGroup>
        <Switch checked={autoFillOn} disabled={autoFillOn} onChange={() => setAutofillOn(true)} label="Turn on Autofill" />
      </FormGroup>
    </form>

    {authResult && <Section title="JS `startAuth` Response" icon="code" collapsible>
      <SectionCard>
        <output>
          <Pre>{JSON.stringify(authResult, null, 2)}</Pre>
        </output>
      </SectionCard>
    </Section>}

    {verifyRequest && <Section title="Backend token verify request" icon="lock" collapsible>
      <SectionCard>
        <output>
          <Pre>
            POST /auth/verify HTTP/1.1{"\n"}
            Content-type: application/json{"\n"}
            Authorization: Basic ******{"\n"}
            {"\n"}
            {JSON.stringify(verifyRequest, null, 2)}
          </Pre>
        </output>
      </SectionCard>
    </Section>}

    {verifyResult && <Section title="Backend token verify response" icon="lock" collapsible>
      <SectionCard>
        <output>
          <Pre>
            HTTP {verifyResult.response.status} {verifyResult.response.statusText}{"\n"}
            {"\n"}
            {JSON.stringify(verifyResult.data, null, 2)}
          </Pre>
        </output>
      </SectionCard>
    </Section>}
  </>)
}

export default SignIn
